import React from 'react'
import { cn } from '../../utils'
import Tabs from '../Tabs'


const ProductDesc = ({ title, desc, desc1, images, imgDivClass, sizes, selectedTab, tabs, setSelectedTab }) => {
  return (
    <div className='bg-Primary_10 rounded-[10px] p-[40px] flex items-center flex-col relative mb-[25px] gap-4'>
      <p className='Title text-center uppercase'>{title}</p>
      <div>

      {desc.map((item1, index1) => {
        return (
          <p className='SubTitle font-medium text-Light_Black text-center text-[16px]' key={index1}>{item1}</p>
        )
      })}
      </div>
      {/* <p className='SubTitle font-medium mt-[20px] text-Light_Black text-center'>{item1}</p> */}
      {/* <p className='SubTitle font-medium text-Light_Black text-center'>{desc1}</p> */}
      <div className={cn('flex gap-x-[40px] max_md:gap-[30px] max_sm:gap-[20px] justify-center mt-[30px]', imgDivClass)}>
        {images?.images.map((itm, idx) =>
          <div className='flex items-end'>

            <img className='max_md:max-h-[90px] max_sm:h-[65px]' key={idx} src={itm} alt='img' />
          </div>
        )}
      </div>
      <p className='Title mt-[40px]'>{'Available Size'}</p>
      <p className='SubTitle font-medium mt-[20px] text-Light_Black text-center'>{sizes}</p>

      <div className='absolute bottom-[-25px]'>
        <Tabs selectedTab={selectedTab} tabs={tabs} setSelectedTab={setSelectedTab} />
      </div>

    </div>
  )
}

export default ProductDesc