import React from 'react'

const InputField = ({placeholder,value,name,handleOnChange,error}) => {
  return (
    <div className='relative'>
      <input className='bg-Primary_10 rounded-[5px] px-[16px] py-[11px] text-paraText font-medium text-Primary outline-0 w-full' placeholder={placeholder} value={value} name={name} onChange={handleOnChange}  />
      <p className='absolute left-1 text-[11px] text-red-500'>{error}</p>
    </div>
  )
}

export default InputField