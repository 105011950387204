import React, { useState } from 'react'
import { Call, FacebookIcon, IndiaMIcon, InstagramIcon, LinkedIn, Location, Mail, WaveAsBg, WhatsappIcon, YTIcon } from '../icons/Footer.Icons'
import FooterImg from "../icons/FooterImg.png"
import InnerContainer from './InnerContainer'
import { ReactComponent as Logo } from "../icons/Logo.svg"
import { NavLink } from 'react-router-dom'
import { cn } from '../utils'

const Footer = () => {
  const [hoverI, setHoverI] = useState(null)
  return (
    <>
      <div
        className='w-full relative h-[416px] max_md:h-fit pt-[150px]'
        style={{ backgroundImage: WaveAsBg }}
      >
        <img className='absolute top-0 right-0 w-[283px] max_md:w-[150px] max_xl:w-[220px]' src={FooterImg} alt='FooterImg' />
        <div className='bg-Light_Blue pb-[30px]'>
          <InnerContainer>
            <div className='grid grid-cols-3 max_md:grid-cols-1 gap-[30px] max_sm:gap-[20px]'>
              {/* LOGO SECTION */}
              <div className='w-full max-w-[353px] max_md:max-w-full flex flex-col'>
                <Logo />
                <div className='mt-[10px] text-paraText max_md:text-paraTextTab text-Light_Black font-medium font-Roborto'>
                  "Empowering Engines, Enhancing Efficiency: Your Journey to Optimal Performance Starts with Us."
                </div>
                <div className='mt-[30px] flex gap-6 items-center'>
                  <a href='https://wa.me/+917874134679' target='_blank'>
                    <div className="cursor-pointer" onMouseEnter={() => setHoverI(1)} onMouseLeave={() => setHoverI(null)}>
                      <WhatsappIcon color={hoverI === 1 ? '#4A55A2' : '#414141'} />
                    </div>
                  </a>
                  <a href='https://www.instagram.com/tricomaxlubricants?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' target='_blank'>
                    <div className="cursor-pointer" onMouseEnter={() => setHoverI(2)} onMouseLeave={() => setHoverI(null)}>
                      <InstagramIcon color={hoverI === 2 ? '#4A55A2' : '#414141'} />
                    </div>
                  </a>
                  <a href='https://www.facebook.com/profile.php?id=61556684760040&mibextid=LQQJ4d' target='_blank'>
                    <div className="cursor-pointer" onMouseEnter={() => setHoverI(3)} onMouseLeave={() => setHoverI(null)}>
                      <FacebookIcon color={hoverI === 3 ? '#4A55A2' : '#414141'} />
                    </div>
                  </a>
                  <a href='https://youtube.com/@tricomaxlubricants?si=cM2NHugVaYS6Z9vl' target='_blank'>
                    <div className="cursor-pointer" onMouseEnter={() => setHoverI(4)} onMouseLeave={() => setHoverI(null)}>
                      <YTIcon color={hoverI === 4 ? '#4A55A2' : '#414141'} />
                    </div>
                  </a>
                  <a href='https://www.indiamart.com/lavanyaenterprise-surat/' target='_blank'>
                    <div className="cursor-pointer" onMouseEnter={() => setHoverI(4)} onMouseLeave={() => setHoverI(null)}>
                      <IndiaMIcon color={hoverI === 4 ? '#4A55A2' : '#414141'} />
                    </div>
                  </a>
                </div>
              </div>

              {/* NAV REDIRECTIONS */}
              <div className='w-full max-w-[99px] max_md:max-w-full pt-3 justify-self-center'>
                <div className='grid grid-cols-1 max_md:gap-[22px] max_md:grid-cols-2 h-full font-Lato pb-[6px]'>
                  <NavLink
                    to={'/'}
                    className={({ isActive }) => {
                      return cn('text-Black font-medium !text-paraText uppercase hover:text-Primary', {
                        'text-Primary ': isActive
                      })
                    }
                    }
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to={'/products'}
                    className={({ isActive }) => {
                      return cn('text-Black font-medium !text-paraText uppercase hover:text-Primary', {
                        'text-Primary ': isActive
                      })
                    }
                    }
                  >
                    Product
                  </NavLink>
                  <NavLink
                    to={'/gallery'}
                    className={({ isActive }) => {
                      return cn('text-Black font-medium !text-paraText uppercase hover:text-Primary', {
                        'text-Primary ': isActive
                      })
                    }
                    }
                  >
                    Gallery
                  </NavLink>
                  <NavLink
                    to={'/contact-us'}
                    className={({ isActive }) => {
                      return cn('text-Black font-medium !text-paraText uppercase hover:text-Primary', {
                        'text-Primary ': isActive
                      })
                    }
                    }
                  >
                    Contact us
                  </NavLink>
                </div>

              </div>

              {/* ADDRESS SECTION */}
              <div className='w-full flex flex-col justify-between gap-[22px] max-w-[388px] max_md:max-w-full pt-3'>
                <a href='tel:7874134679' className='flex gap-x-[16px] items-start cursor-pointer'>
                  <div className='min-w-[16px] w-[16px]'>
                    <Call />
                  </div>
                  <div className='font-Roborto text-paraText max_md:text-paraTextTab text-black hover:text-Primary  cursor-pointer'>7874134679</div>
                </a>
                <a href='mailto:tricomaxdirector@gmail.com' className='flex gap-x-[16px] items-start cursor-pointer'>
                  <div className='min-w-[16px] w-[16px] pt-[1px]'>
                    <Mail />
                  </div>
                  <div className='font-Roborto text-paraText max_md:text-paraTextTab text-black hover:text-Primary  cursor-pointer'>tricomaxdirector@gmail.com</div>
                </a>
                <a href='https://maps.app.goo.gl/BzRRWnd4YGFNofnd6' target='_blank' className='flex gap-x-[16px] items-start cursor-pointer'>
                  <div className='min-w-[16px] w-[16px]'>
                    <Location />
                  </div>
                  <div className='font-Roborto text-paraText max_md:text-paraTextTab text-black hover:text-Primary  cursor-pointer'>First floor, shop no 101, F type INDL Complex Somnath, Dabhel, Daman, Dadra and Nagar haveli and daman and diu Gujarat, 396210</div>
                </a>
              </div>
            </div>
          </InnerContainer>
        </div>
      </div >
      <div className='bg-Primary text-white text-center w-full font-Roborto text-paraText max_md:text-paraTextTab font-medium py-[10px]'>
        © 2023 TRICOMAX. All rights reserved
      </div>
    </>
  )
}

export default Footer